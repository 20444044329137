// BH Starter Version *** DONT CHANGE ***
// Version: 5.0.11

export const environment = {
  // Environment Name
  env: 'DEV',

  // Is Production Environment?
  production: false,

  // App Name/ID
  appName: 'CAREROSTER',

  // App Description (full name)
  appDescription: 'Care Roster',

  // App Version Number
  appVersion: '5.5.4',

  // App's REST Service API Root URL
  apiUrl: 'https://mobiledev.api.baystatehealth.org/careroster',

  // App's Uploaded Files Root URL
  filesUrl: 'https://dev.caseshare.apps.baystatehealth.org',

  // App's Push Notifications App ID for OneSignal
  pushId: '581c0654-250c-4f22-820d-ac9241aa0855',

  // This is the device auth API token
  deviceToken: '01ae81c1-5702-4b0d-a081-5d1870a99c53',

  // Public Rosters token
  publicRostersToken: '6f95f382-f485-4248-ac14-24dc88625a37',

  // Verlocker API URL
  verlockerUrl: 'https://mobiledev.api.baystatehealth.org/verlocker',

  // BH AppStore Deep Link URL
  appstoreUrl: 'https://mobile.baystatehealth.org/appstore/',

  // Reset password link URL
  resetPwdUrl: 'https://baystatehealth.service-now.com/nav_to.do?uri=%2F$pwd_reset.do%3Fsysparm_url%3Dpassword_reset',

  // Change password security questions link URL
  changePwdQuestionsUrl: 'https://baystatehealth.service-now.com/b_sp?id=pwd_reset_enrollment',

  // Set default theme: auto, light, dark
  // Auto will select theme based on browser's preferred theme
  theme: 'auto',

  // Store token in local storage to remember user?
  // Patient/Secure Apps should set this to false
  storeToken: true,

  // Allow Biometrics to login
  // This will allow users to login using biometrics (fingerprint, Touch ID or Face ID)
  allowBiometrics: false,

  // Require PIN code enabled on device
  // Patient/Secure Apps will require that the PIN code is enabled
  // If PIN is not in use, app will block user
  requirePin: true,

  // Require timeout
  // Patient/Secure Apps will require that timeout is enabled
  // On timeout, user will be bumped
  requireTimeout: false,

  // Timeout threshold
  // Determine the number of seconds when timeout will occur
  // 15 minutes = 900000
  timeoutThreshold: 900000,

  preventSearchIndexing: true, // Custom flag for preventing indexing in dev

 //Metric Codes To Filter
 filterCodes: [
  {
    code: 'm6012'
  },
  {
    code: 'm6012a'
  },
  {
    code: 'm6014'
  },
  {
    code: 'm6014a'
  },
  {
    code: 'm6020'
  }
],

  hideCode: 'm6005',


  footerCodes: [
    {
      code: 'm6017'
    },
    {
      code: 'm6018'
    },
    {
      code: 'm7017'
    },
    {
      code: 'm7018'
    },
  ],

  // API Key Index
  keys: [
    {
      deviceId: 'CROSSX',
      skey: 'ca5fc174-4a80-41aa-bf22-a90abefd1ff2'
    },
    {
      deviceId: 'BMC_ED_ROLE_AM',
      skey: 'a9f1a328-e630-498c-b4fc-1c6feb315375'
    },
    {
      deviceId: 'BMC_ED_ROLE_CN',
      skey: '098eb52c-2640-4dff-a3e7-919e172d098a'
    },
    {
      deviceId: 'BMC_ED_ROLE_TRAUMA_RN',
      skey: '4ae5fbd4-88ae-4fca-9057-044892e18ee7'
    },
    {
      deviceId: 'BMC_ED_ROLE_FC',
      skey: 'a640893a-7c01-4149-ac3c-b3c7a7db7b3c'
    },
    {
      deviceId: 'BMC_ED_ROLE_ABPOD_LD',
      skey: 'b74a5777-c47d-4797-bbbb-64ef42929c1c'
    },
    {
      deviceId: 'BMC_ED_ROLE_CPOD_LD',
      skey: 'aa30b4b7-77a9-40c4-a96c-d34f9d9edd32'
    },
    {
      deviceId: 'BMC_ED_ROLE_DPOD_LD',
      skey: '20ee9f19-00f4-4c12-ab6d-734181937a02'
    },
    {
      deviceId: 'BMC_ED_ROLE_ALL_POD_LD',
      skey: '650833db-f1e6-4726-a204-224c39f3efe6'
    },
    {
      deviceId: 'BMC_ED_ROLE_LD',
      skey: '1cf4bc1c-310c-4731-8731-c402e04fed63'
    },
    {
      deviceId: 'MATT_TEST_ALL',
      skey: '5ff5dcfe-8aad-498c-af1f-86469913bf2d'
    },
    {
      deviceId: 'BMC_ED_ED',
      skey: '9bebb5c6-1f64-448b-becf-d4ad63b1c952'
    },
    {
      deviceId: 'BMC_ED_TRIAGE',
      skey: '86baa9f0-1a18-4f05-8378-706aafb22a03'
    },
    {
      deviceId: 'BMC_ED_ABPOD',
      skey: '60308e0e-fec5-4c4c-bd9c-87a0d4fb33ef'
    },
    {
      deviceId: 'BMC_ED_CPOD',
      skey: 'd0d8f9d9-e384-4687-b3e2-e21ba56851ba'
    },
    {
      deviceId: 'BMC_ED_DPOD',
      skey: 'f32640a3-c178-4a68-b647-b363ac544a1e'
    },
    {
      deviceId: 'BMC_ED_EPOD',
      skey: '7471d24f-a054-4928-b6e3-1b8b45589969'
    },
    {
      deviceId: 'BMC_ED_EDASSIST',
      skey: '9e5d4da3-303f-451d-afb4-6b4bf68e961b'
    },
    {
      deviceId: 'BMC_ED_PATADVOC',
      skey: '3b43d0df-4203-4640-838d-b3f8cf13c9b4'
    },
    {
      deviceId: 'CROSSX_ALL',
      skey: '41f72fc8-5d91-4eda-b9c0-465bb517b3af'
    },
    {
      deviceId: 'BMC_ED_1',
      skey: '43edd65d-638b-47e9-a7b5-e8a71c522914'
    },
    {
      deviceId: 'MATT_TEST_1',
      skey: '07e12068-d12b-442f-a26e-c61237882a4e'
    },
    {
      deviceId: 'BMC_ED_ALL',
      skey: '8e79962e-bd09-4a83-8c02-dac1ebf0eb85'
    },
    {
      deviceId: '18',
      skey: 'aea4aff2-4d35-4354-b55a-88171dc8fbd0'
    },
    {
      deviceId: 'BMC_ED_ROLES_RN',
      skey: '19186220-9a65-48e4-b34c-40e5f1dfba05'
    },
    {
      deviceId: 'BMC_ED_TRIAGE_RN',
      skey: '9213e695-bef0-4242-9cee-c802e8e76315'
    },
    {
      deviceId: 'BMC_ED_ABPOD_RN',
      skey: '52c8cc77-2f51-4829-b310-c7f6edcd1641'
    },
    {
      deviceId: 'BMC_ED_CPOD_RN',
      skey: 'b905fcf2-0775-43ce-81f9-fe61fc54ed7e'
    },
    {
      deviceId: 'BMC_ED_DPOD_RN',
      skey: 'ed24053e-dcdd-4ee4-9bbe-9bdb4b6f2419'
    },
    {
      deviceId: 'BMC_ED_EPOD_RN',
      skey: '1527cb67-4e44-4780-9c69-fa427acfb48c'
    },
    {
      deviceId: 'BMC_ED_ADLT_SURGE',
      skey: 'd7902729-5d68-4b05-90b4-50a01881ce07'
    },
    {
      deviceId: 'BMC_ED_PEDI_SURGE',
      skey: '51e7143f-20c3-426b-8f65-20df39607a1d'
    },
    {
      deviceId: 'BMC_SEC_ALL',
      skey: 'c432988c-7320-441b-8dd0-2821035dd46e'
    },
  ],

    // Charge-Report Questions Configuration
    chargeReportQuestionConfig: {

      // Application Id
      applicationId: 'CHARGE',
  
      // Form SEQ
      frmSeq: 1,

      //Header Value
      header: 'Charge Nurse Daily Report'  
    },
    // Charge-Report Questions Configuration
    pediChargeReportQuestionConfig: {

      // Application Id
      applicationId: 'PEDI-CHARGE',
  
      // Form SEQ
      frmSeq: 2,

      //Header Value
      header: 'Pedi Charge Nurse Daily Report'  
    },

    // Charge-Report Questions Configuration
    covidAPITestingConfig: {

       // Symptomatic COVID-19 Testing information Form ID
      // PKA: FORMID_TESTSCREENING
      formId: 'COVID19TESTING',

      // Symptomatic COVID-19 Testing Application Id
      // PKA: SCHTESTINGAPP
      applicationId: 'WWCOVIDTEST',

      // Symptomatic COVID Testing Campaign Seq
      // PKA: EMPLOYEE_TESTING_CAMPAIGN_SEQ
      campaignSeq: 9,

       //Header Value
       header: 'COVID 19 TESTING'  
    },

      // COVID-Positive Screening Configuration
  covidPositiveScreening: {

    // COVID- Positive Application Id
    applicationId: 'WWCOVIDPOS',

    // Screening Form ID
    formId: 'COVIDPOSITIVE',

    // Question ID Phone Question
    phoneQsSeq: 55,

     //Header Value
     header: 'COVID POS 19 TESTING'  

  },


};
